// IE兼容
import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import startMicroApp from './microapp'

import 'ant-design-vue/dist/antd.css'

Vue.use(Antd)

Vue.config.productionTip = false

startMicroApp()

window.app = new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app')
