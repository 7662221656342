import {
	INITIAL_STATE,
	SET_AUTHORIZATION,
	SET_USERINFO,
	SET_FILESERVER,
	SET_PICSERVER,
	CHANGE_THEME,
	SET_USERAVATAR,
	CHANGE_MAP,

} from './mutation-types'

const mutations = {
	[INITIAL_STATE](state) {
		state.authorization = ''

		state.fileServer = ''

		state.userInfo.userId = ''
		state.userInfo.tenantOrgId = ''
		state.userInfo.tenantOrgName = ''
		state.userInfo.companyId = ''
		state.userInfo.companyName = ''
		state.userInfo.departmentId = ''
		state.userInfo.departmentName = ''
		state.userInfo.jobId = ''
		state.userInfo.jobName = ''
		state.userInfo.userType = ''
		state.userInfo.loginName = ''
		state.userInfo.name = ''
		state.userInfo.mobile = ''
		state.userInfo.photo = ''
	},
	[SET_AUTHORIZATION](state, authorization) {
		state.authorization = authorization
	},
	[SET_USERINFO](state, userInfo) {
		state.userInfo.userId = userInfo.id
		state.userInfo.tenantOrgId = userInfo.tenantOrgId
		state.userInfo.tenantOrgName = userInfo.tenantOrgName
		state.userInfo.companyId = userInfo.companyId
		state.userInfo.companyName = userInfo.companyName
		state.userInfo.departmentId = userInfo.departmentId
		state.userInfo.departmentName = userInfo.departmentName
		state.userInfo.jobId = userInfo.jobId
		state.userInfo.jobName = userInfo.jobName
		state.userInfo.userType = userInfo.userType
		state.userInfo.loginName = userInfo.loginName
		state.userInfo.name = userInfo.name
		state.userInfo.mobile = userInfo.mobile
		state.userInfo.photo = userInfo.photo
		state.userInfo.companyTypeRole = userInfo.companyTypeRole
		state.userInfo.screenShowStatus = userInfo.screenShowStatus
	},
	[SET_FILESERVER](state, fileServer) {
		state.fileServer = fileServer
	},
	['SET_SUBMITLOADDING'](state, status) {
		state.submitLoadding = status
	},
	['SET_PTYPE'](state, type) {
		state.pType = type
	},
	[SET_PICSERVER](state, picServer) {
		state.picServer = picServer
	},
	[CHANGE_THEME](state, theme) {
		state.theme = theme
	},
	[SET_USERAVATAR](state, photo) {
		state.userInfo.photo = photo
	},
	[CHANGE_MAP](state, map) {
		state.map.id = map.id
		state.map.type = map.type
		state.map.name = map.name
	},
}

export default mutations
